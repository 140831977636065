import axios from 'axios';

export default class EspecialidadService {

	getEspecialidades() {
		//return axios.get('assets/layout/data/especialidades.json').then(res => res.data.data);
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/especialidades`;
		const url = `/api/especialidades`;
		return axios.get(url).then(res=>res.data.data);
	}

	createEspecialidades(especialidades) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/especialidades`;
		const url = `/api/especialidades`;
		return axios.post(url,especialidades).then(res=>res.data.data);
	}
	
	updateEspecialidades(especialidades) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/especialidades/${especialidades.id_especialidad}`;
		const url = `/api/especialidades/${especialidades.id_especialidad}`;
		return axios.put(url,especialidades).then(res=>res.data.data);
	}
	
	deleteEspecialidades(especialidades) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/especialidades/${especialidades.id_especialidad}`;
		const url = `/api/especialidades/${especialidades.id_especialidad}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getEspecialidadesExport() {
		//return axios.get('assets/layout/data/especialidades.json').then(res => res.data.data);
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/especialidadesexport`;
		const url = `/api/especialidadesexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}
